import React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby"

import { withStyles } from '@material-ui/core/styles';
import BlogPostSection from '../components/BlogPostSection'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

class BlogPage extends React.Component {
  render() {
    // store up the array of images fluid value from imageSharp
    const { data, classes } = this.props
    const pages = data.pages.edges.map(item => item.node)
    const blogNav = data.navs.menu.items.find(item => item.name === "blog")
    const pagedata = { title: blogNav.title, description: blogNav.description }
    return (
      <Layout
        className={classes.root}
        location={this.props.location}
        pagedata={pagedata}
      >
      {pages && pages.filter(t => !t.frontmatter.draft && (t.frontmatter.type && t.frontmatter.type === "blog"))
        .map((item, i) => {
        const blogItem = {
          image: item.frontmatter.image,
          title: item.frontmatter.title,
          description: item.frontmatter.description,
          path: item.frontmatter.path
        }
        return (<BlogPostSection key={i} data={blogItem} />)
        })
      }
      </Layout>
    )
  }
}

export default withStyles(styles)(BlogPage)


export const blogPages = graphql`
  query { 
    pages: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
      edges {
        node {
          frontmatter {
            title
            image
            description
            path
            date
            draft
            type
          }
        }
      }
    }
    navs: navigation {
      menu {
        items {
          name
          title
          label
          description
        }
      }
    }
  }
`
