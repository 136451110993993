import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Markdown from 'react-markdown'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20, // Half the value of the grid spacing
  },
  card: {
    minWidth: 345
  },
  action: {
    marginLeft: "auto"
  },
  title: {
    fontFamily: theme.typography.fontFamilySecond,
    fontWeight: 500,
    color: theme.palette.primary.dark
  },
  desc: {
    "& img": {
      maxHeight: 320,
      "@media (max-width: 1280px)": {
        maxHeight: 160,
      },
      width: "auto"
    }
  }
})

class BlogPostSection extends React.Component {
  render() {
    const { data, classes } = this.props

    return (
      <div className={classes.root}>
      <Grid container spacing={40}>
        <Grid className={classes.header} item xs={12}>
          <Grid container className={classes.pageConent} justify="center" spacing={16}>
            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={6}>

              <Card className={classes.card}>
                <CardActionArea component={Link} to={data.path}>
                  <CardContent>
                    <Typography className={classes.title} gutterBottom variant="h4">{data.title}</Typography>
                    <Typography className={classes.desc} component={Markdown}>{data.description}</Typography>
                    <Markdown source={data.content} />
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button component={Link} to={data.path} className={classes.action} size="small" color="primary">Read ...</Button>
                </CardActions>
              </Card>

            </Grid>
            <Grid item xs={12} sm={3} />
          </Grid>
        </Grid>
      </Grid>
      </div>      
      )
  }
}

BlogPostSection.propTypes = {
  data: PropTypes.object,
}

export default withStyles(styles)(BlogPostSection)
